import { ReactNode } from "react";
import { checkContrast } from "../../shared/colorHelpers";

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../../tailwind.config.js");

const fullConfig = resolveConfig(tailwindConfig);

export type ButtonType = "submit" | "reset" | "button";
type ButtonColor = "primary" | "disabled" | "alert";

const Button = ({
  type = "button",
  onClick,
  color = "primary",
  children,
  round,
  icon,
  offset,
  disabled,
}: {
  type?: ButtonType;
  onClick?: () => void;
  color?: ButtonColor;
  children: ReactNode;
  round?: boolean;
  icon?: boolean;
  offset?: boolean;
  disabled?: boolean;
}) => {
  const twColor = fullConfig.theme.colors?.[disabled ? "disabled" : color];

  return (
    <button
      type={type}
      onClick={onClick}
      className={`
        font-semibold cursor-pointer whitespace-nowrap rounded uppercase inline-flex items-center justify-center space-x-2 transition-all duration-300 shadow hover:shadow-lg 
        ${round ? "rounded-full" : ""} 
        ${icon ? "p-2" : "py-1 px-8"} 
        ${
          offset
            ? "absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2"
            : "relative"
        }
      `}
      style={{ color: checkContrast(twColor), background: twColor }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
