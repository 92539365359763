import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA-rLQVhdJg17f5HFHSjcvHBoKhT6F-0E0",
  authDomain: "planner-922e6.firebaseapp.com",
  projectId: "planner-922e6",
  storageBucket: "planner-922e6.appspot.com",
  messagingSenderId: "527468699557",
  appId: "1:527468699557:web:8a8ae64b61d4df975bd24c",
};

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export const customer = "V2sxAaICHcBzbd7ivgkg";

export const getAnimals = async () => {
  const postCol = collection(db, "animals");
  const postSnapshot = await getDocs(postCol);
  return postSnapshot.docs.map((doc) => doc.data());
  // Set the result to the useState.
};

//getAnimals();
