import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { db } from "../firebase/firebase";
import { formattedDateFull, formattedTime } from "../shared/timeHelper";
import { Event, Settings } from "../types";
import Button from "./ui/Button";
import CustomSwitch from "./ui/Switch";

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../tailwind.config.js");

const fullConfig = resolveConfig(tailwindConfig);

const CalendarEventEdit = ({
  date,
  eventID,
  isAdmin,
  settings,
  show,
  setShow,
  type,
}: {
  date?: Date;
  eventID?: string;
  isAdmin?: boolean;
  settings?: Settings;
  show?: boolean;
  setShow?: any;
  type: "create" | "edit";
}) => {
  const [dateEdit, setDateEdit] = useState<Date>();
  const [event, setEvent] = useState<Event>();

  const [start, setStart] = useState("00:00");
  const [end, setEnd] = useState("00:00");
  const [endError, setEndError] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [maxParticipants, setMaxParticipants] = useState<number | null>(
    settings ? settings.defaultUserLimit : null
  );
  const [maxParticipantsInit, setMaxParticipantsInit] = useState<number>();
  const [color, setColor] = useState<string>(
    fullConfig.theme.colors?.["primary"]
  );

  const [showMaxUsers, setShowMaxUsers] = useState(true);
  const [noLimit, setNoLimit] = useState(false);
  const [countsTowardsLimit, setCountsTowardsLimit] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const changesExist =
    type === "edit" &&
    event &&
    dateEdit &&
    !(
      new Date(event.date.seconds * 1000).getTime() === dateEdit.getTime() &&
      formattedTime(event.start.seconds * 1000) === start &&
      formattedTime(event.end.seconds * 1000) === end &&
      event.title === title &&
      event.description === description &&
      event.maxParticipants === maxParticipants &&
      event.showMaxUsers === showMaxUsers &&
      event.countsTowardsLimit === countsTowardsLimit &&
      event.color === color
    );

  useEffect(() => {
    if (eventID && type === "edit") {
      (async () => {
        onSnapshot(doc(db, "events", eventID), (doc) => {
          const res = doc.data();
          if (res) {
            setEvent(doc.data() as Event);
          }
        });
      })();
    }
  }, [eventID, type]);

  useEffect(() => {
    if (event && type === "edit") {
      setDateEdit(new Date(event?.date.seconds * 1000));
      setStart(formattedTime(event.start.seconds * 1000));
      setEnd(formattedTime(event.end.seconds * 1000));
      setTitle(event.title);
      setDescription(event.description || "");
      setMaxParticipants(event.maxParticipants);
      setMaxParticipantsInit(event.maxParticipants);
      setShowMaxUsers(event.showMaxUsers);
      setNoLimit(event.maxParticipants === 0);
      setCountsTowardsLimit(event.countsTowardsLimit);
      setColor(event.color || "");
    }
  }, [event, type]);

  const writeEvent = async (e: any) => {
    e.preventDefault();

    if (date && type === "create") {
      setIsLoading(true);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const dateStringStart = `${month}/${day}/${year} ${start}`;
      const dateStringEnd = `${month}/${day}/${year} ${end}`;
      const dateString = `${month}/${day}/${year} 00:00:00`;

      if (new Date(dateStringStart) < new Date(dateStringEnd)) {
        await addDoc(collection(db, `events`), {
          start: Timestamp.fromDate(new Date(dateStringStart)),
          end: Timestamp.fromDate(new Date(dateStringEnd)),
          date: Timestamp.fromDate(new Date(dateString)),

          title: title,
          description: description,
          maxParticipants: noLimit ? 0 : Number(maxParticipants),
          showMaxUsers: showMaxUsers,
          countsTowardsLimit: countsTowardsLimit,
          color: color,
        }).then(() => {
          setShow(false);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
        setEndError("End has to be after start");
      }
    }
  };

  const editEvent = async (e: any) => {
    e.preventDefault();

    if (
      type === "edit" &&
      event &&
      dateEdit &&
      maxParticipants !== undefined &&
      changesExist &&
      eventID
    ) {
      setIsLoading(true);
      const day = dateEdit.getDate();
      const month = dateEdit.getMonth() + 1;
      const year = dateEdit.getFullYear();

      const dateStringStart = `${month}/${day}/${year} ${start}`;
      const dateStringEnd = `${month}/${day}/${year} ${end}`;
      const dateString = `${month}/${day}/${year} 00:00:00`;

      if (new Date(dateStringStart) < new Date(dateStringEnd)) {
        await updateDoc(doc(db, `events`, eventID), {
          start: Timestamp.fromDate(new Date(dateStringStart)),
          end: Timestamp.fromDate(new Date(dateStringEnd)),
          date: Timestamp.fromDate(new Date(dateString)),

          title: title,
          description: description,
          maxParticipants: noLimit ? 0 : Number(maxParticipants),
          showMaxUsers: showMaxUsers,
          countsTowardsLimit: countsTowardsLimit,
          color: color,
        }).then(() => {
          console.log("edited");
          setShow(false);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
        setEndError("End has to be after start");
      }
    } else console.log("nothing to change");
  };

  const handleStart = (e: any) => {
    setStart(e.target.value);
  };
  const handleEnd = (e: any) => {
    setEnd(e.target.value);
  };
  const handleTitle = (e: any) => {
    setTitle(e.target.value);
  };
  const handleDescription = (e: any) => {
    setDescription(e.target.value);
  };
  const handleMaxParticipants = (e: any) => {
    setMaxParticipants(e.target.value);
  };
  const handleColor = (e: any) => {
    setColor(e.target.value);
  };

  const handleNoLimit = (nl: boolean) => {
    setNoLimit(nl);
    if (nl) setMaxParticipants(0);
    else if (maxParticipantsInit) setMaxParticipants(maxParticipantsInit);
  };

  console.log("add / edit event");
  console.log(color);

  return (
    <div>
      <div className="cea-header">
        {date
          ? formattedDateFull(date)
          : dateEdit && formattedDateFull(dateEdit)}
      </div>
      <form onSubmit={type === "create" ? writeEvent : editEvent}>
        <div className="form-group">
          <label>Start</label>
          <input type="time" value={start} onChange={handleStart} required />
        </div>
        <div className="form-group">
          <label>End</label>
          <input type="time" value={end} onChange={handleEnd} required />
          {endError && <div className="form-group-error">{endError}</div>}
        </div>
        <div className="form-group">
          <label>Title</label>
          <input type="text" value={title} onChange={handleTitle} required />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea value={description} onChange={handleDescription} />
        </div>
        <div className="form-group">
          <label>Color</label>
          <input type="color" value={color} onChange={handleColor} />
        </div>
        <div className="form-group">
          <div className="form-group-switch">
            <label>No limit</label>
            <CustomSwitch
              toggle={noLimit}
              onChange={() =>
                type === "create"
                  ? setNoLimit(!noLimit)
                  : handleNoLimit(!noLimit)
              }
            />
          </div>
          <div className="form-group-switch">
            <label>Show Max. users</label>
            <CustomSwitch
              toggle={showMaxUsers}
              onChange={() => setShowMaxUsers(!showMaxUsers)}
            />
          </div>
          <div className="form-group-switch">
            <label>Counts towards limit</label>
            <CustomSwitch
              toggle={countsTowardsLimit}
              onChange={() => setCountsTowardsLimit(!countsTowardsLimit)}
            />
          </div>
        </div>
        {!noLimit && (
          <div className="form-group">
            <label>Max. participants (0 = unlimited)</label>
            <input
              type="number"
              value={maxParticipants || "0"}
              onChange={handleMaxParticipants}
              min={0}
              disabled={isLoading}
            />
          </div>
        )}
        <Button
          type="submit"
          offset
          disabled={type === "edit" && !changesExist}
        >
          {type === "create" ? "Create" : "Edit"}
        </Button>
      </form>
      {type === "edit" && (
        <div className="event-admin">
          <div
            className="btn btn-primary btn-round btn-icon"
            onClick={() => setShow(false)}
          >
            <X />
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarEventEdit;
