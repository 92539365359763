import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { Overlay } from "../components/Overlay";
import { db } from "../firebase/firebase";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mailIsLoading, setMailIsLoading] = useState(false);
  const [passwordResetIsOpen, setPasswordResetIsOpen] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  const signIn = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await updateDoc(doc(db, "users", userCredential.user.uid), {
          emailVerified: userCredential.user.emailVerified,
          email: userCredential.user.email,
        }).then(() => {
          setIsLoading(false);
          navigate("/");
        });
      })
      .catch((error) => {
        setError("Email or Password wrong");
        setIsLoading(false);
        console.log(error.code);
        console.log(error.message);
      });
  };

  const sendPasswordResetMail = (e: any) => {
    e.preventDefault();

    setMailIsLoading(true);

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMailIsLoading(false);
        setPasswordResetIsOpen(false);
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
      });
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <div className="container container-center">
        <h1>Sign in</h1>
        <form onSubmit={signIn}>
          {error && <div className="form-error">{error}</div>}
          <div className="form-group">
            <label>E-Mail</label>
            <input type="text" value={email} onChange={handleEmail} required />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={handlePassword}
              required
            />
            <span
              className="form-group-hint cursor-pointer"
              onClick={() => setPasswordResetIsOpen(true)}
            >
              Forgot password
            </span>
          </div>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isLoading}
          >
            <span>Submit</span>
            {isLoading && <Loading inline />}
          </button>
        </form>
        <div className="text-center text-gray-400 p-4">
          Don't have an account? <Link to={"/sign-up"}>Sign up</Link>
        </div>
      </div>

      <Overlay isOpen={passwordResetIsOpen} setIsOpen={setPasswordResetIsOpen}>
        <form onSubmit={sendPasswordResetMail}>
          <div className="form-group">
            <label>E-Mail</label>
            <input type="email" value={email} onChange={handleEmail} />
          </div>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={mailIsLoading}
          >
            <span>Submit</span>
            {mailIsLoading && <Loading inline />}
          </button>
        </form>
      </Overlay>
    </>
  );
};

export default SignIn;
