// const userLocale =
//   navigator.languages && navigator.languages.length
//     ? navigator.languages[0]
//     : navigator.language;

const locale = Intl.DateTimeFormat().resolvedOptions().locale;

console.log(locale);
//const locale = "de-DE";

export const formattedDateFull = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  }).format(new Date(timestamp));
};

export const formattedDateTimeFull = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(timestamp));
};

export const formattedTime = (timestamp: number): string => {
  return new Intl.DateTimeFormat(locale, {
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(timestamp));
};

export const formattedWeekDay = (date: Date): string => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "short",
  }).format(new Date(date));
};

export const formattedDay = (date: Date): string => {
  return new Intl.DateTimeFormat(locale, {
    day: "numeric",
  }).format(new Date(date));
};

export const sameDay = (date1: Date, date2: Date): boolean => {
  if (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

export const compareDates = (
  date1: { toDateString: () => any },
  date2: { toDateString: () => any }
): boolean => {
  if (date1.toDateString() === date2.toDateString()) {
    return true;
  } else {
    return false;
  }
};
