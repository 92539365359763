/** @type {import('tailwindcss').Config} */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("tailwindcss/colors");

module.exports = {
  content: ["./src/**/*.{tsx,ts}", "./public/*.html"],
  theme: {
    extend: {
      colors: {
        textColor: "#212529",
        textColorLight: "#ECE7D2",
        primary: "var(--primary)", //#E9B335,
        secondary: "var(--secondary)", //#2B2A38
        disabled: colors.gray[300],
        alert: "#925E45",
      },
    },
  },
  plugins: [],
};
