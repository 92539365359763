const Loading = ({
  inline,
  inContainer,
  noBg = false,
  noFloat,
  absolute,
}: {
  inline?: boolean;
  inContainer?: boolean;
  noBg?: boolean;
  noFloat?: boolean;
  absolute?: boolean;
}) => {
  if (inline)
    return (
      <div
        className={`loading loading-inline ${
          noFloat ? "loading-no-float" : ""
        }`}
      />
    );
  else
    return (
      <div
        className={`
          loading-wrapper 
          ${inContainer ? "loading-wrapper-in-container" : ""} 
          ${noBg ? "loading-wrapper-noBg" : ""} 
          ${absolute ? "loading-wrapper-absolute" : ""}
        `}
      >
        <div className="loading" />
      </div>
    );
};

export default Loading;
