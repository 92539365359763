import { ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import { X } from "react-feather";

export const OverlayStandalone = ({
  children,
  trigger,
  triggerClassName,
  closeElement,
}: {
  children: ReactNode;
  trigger: ReactNode;
  triggerClassName?: string;
  closeElement?: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={triggerClassName} onClick={() => setIsOpen(true)}>
        {trigger}
      </div>
      <Transition
        show={isOpen}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="overlay-wrapper"
      >
        <div className="overlay-backdrop" onClick={() => setIsOpen(false)} />
        <div className="overlay">
          <div className="overlay-header">
            <X onClick={() => setIsOpen(false)} />
          </div>
          <div className="overlay-content">
            {children}
            {closeElement && <span>{closeElement}</span>}
          </div>
        </div>
      </Transition>
    </>
  );
};

export const Overlay = ({
  children,
  isOpen,
  setIsOpen,
  hasOffsetButton,
  classNameOverlay,
}: {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: any;
  hasOffsetButton?: boolean;
  classNameOverlay?: string;
}) => {
  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="overlay-wrapper"
    >
      <div className="overlay-backdrop" onClick={() => setIsOpen(false)} />
      <div
        className={`
        overlay 
        ${hasOffsetButton ? "overlay-has-offset-button" : ""} 
        ${classNameOverlay ? classNameOverlay : ""} 
      `}
      >
        <div className="overlay-header">
          <X onClick={() => setIsOpen(false)} />
        </div>
        <div className="overlay-content">{children}</div>
      </div>
    </Transition>
  );
};
