import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import { ReactNode, useEffect, useState } from "react";
import { Home, LogIn } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase/firebase";
import { checkContrast } from "../shared/colorHelpers";
import { OwnUser } from "../types";

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../tailwind.config.js");

const fullConfig = resolveConfig(tailwindConfig);

const HeaderItem = ({
  url,
  children,
  className,
}: {
  url: any;
  children: ReactNode;
  className?: string;
}) => {
  const classes = `cursor-pointer text-textColor ${className ? className : ""}`;
  return typeof url === "string" ? (
    <Link to={url} className={classes}>
      {children}
    </Link>
  ) : (
    <div onClick={url} className={classes}>
      {children}
    </div>
  );
};

const Header = () => {
  const [users, setUsers] = useState<OwnUser[]>();
  const [DBuser, setDBuser] = useState<OwnUser>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [scrollTop, setScrollTop] = useState(0);

  const navigate = useNavigate();

  const signOutUser = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/sign-in");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    onSnapshot(collection(db, `users`), (snapshot) => {
      setUsers(snapshot.docs.map((doc) => doc.data()) as OwnUser[]);
    });
  }, []);

  useEffect(() => {
    window.onscroll = function () {
      setScrollTop(window.scrollY);
    };
  });

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (u) => {
      if (u) {
        setUser(u);
        if (users) setDBuser(users.find((uu) => uu.uid === u.uid));
        setIsAdmin(DBuser?.isAdmin || false);
      }
    });
  }, [DBuser?.isAdmin, navigate, users]);

  const currentPathName = window.location.pathname;
  return (
    <header className={`fixed top-0 left-0 right-0 z-10 p-2`}>
      <div
        className={`mx-auto max-w-screen-lg flex items-center justify-between space-x-4 p-2 py-4 md:p-4 rounded-lg transition-all duration-300 ${
          scrollTop > 20 ? "bg-primary shadow" : ""
        }`}
        style={{ color: checkContrast(fullConfig.theme.colors?.["primary"]) }}
      >
        <div className="text-secondary">
          {currentPathName === "/" ? (
            <Home className="text-secondary w-6 h-6" />
          ) : (
            <Link to="/">
              <Home className="text-secondary w-6 h-6" />
            </Link>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {isAdmin && (
            <Link to="/admin" className="text-red-600">
              Admin
            </Link>
          )}
          {user ? (
            <>
              <HeaderItem url="/events">Events</HeaderItem>
              <HeaderItem url="/profile">Profile</HeaderItem>
              <HeaderItem url={signOutUser}>Sign Out</HeaderItem>
            </>
          ) : (
            currentPathName !== "/sign-in" && (
              <div className="header-sign-in">
                <Link to="/sign-in" className="">
                  <LogIn />
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
