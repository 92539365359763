import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  updateProfile,
  User,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase/firebase";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const navigate = useNavigate();
  const auth = getAuth();
  const createUser = (e: any) => {
    e.preventDefault();

    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validPassword = password.length >= 8;
    const validName = name.length >= 3;

    if (!validEmail) setEmailError("Invalid E-Mail");
    else setEmailError("");
    if (!validPassword) setPasswordError("Password too short");
    else setPasswordError("");
    if (!validName) setNameError("Name too short");
    else setNameError("");

    const writeUser = async (uid: string) => {
      await setDoc(doc(db, "users", uid), {
        email: email,
        displayName: name,
        uid: uid,
      });
    };

    const updateUser = (user: User) => {
      updateProfile(user, {
        displayName: name,
      });
    };

    if (validEmail && validPassword && validName) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          writeUser(userCredential.user.uid)
            .then(() => updateUser(userCredential.user))
            .then(() => {
              sendEmailVerification(userCredential.user).then(() =>
                navigate("/")
              );
            });
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use")
            setEmailError("E-Mail already in use");
          console.log(error.code);
          console.log(error.message);
        });
    }
  };
  const handleName = (e: any) => {
    setName(e.target.value);
  };
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };
  return (
    <div className="container container-center">
      <h1>Sign up</h1>
      <form onSubmit={createUser}>
        <div className="form-group">
          <label>Name</label>
          <input type="text" value={name} onChange={handleName} required />
          {nameError && <div className="form-group-error">{nameError}</div>}
        </div>
        <div className="form-group">
          <label>E-Mail</label>
          <input type="text" value={email} onChange={handleEmail} required />
          {emailError && <div className="form-group-error">{emailError}</div>}
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={handlePassword}
            required
          />
          {passwordError && <div className="form-error">{passwordError}</div>}
        </div>
        <button className="btn btn-primary" type="submit">
          Sign up
        </button>
      </form>
      <div className="text-center text-gray-500 text-sm p-4">
        Already have an account? <Link to={"/sign-in"}>Sign in</Link>
      </div>
    </div>
  );
};

export default SignUp;
