import { User } from "firebase/auth";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Edit, Info, Trash2, Users } from "react-feather";
import ReactTooltip from "react-tooltip";
import colors from "tailwindcss/colors";
import { db } from "../firebase/firebase";
import { checkContrast } from "../shared/colorHelpers";
import { formattedDateTimeFull } from "../shared/timeHelper";
import { Event, OwnUser } from "../types";
import CalendarEventEdit from "./CalendarEventEdit";
import { Overlay } from "./Overlay";
import Button from "./ui/Button";

const CalendarEvent = ({
  eventId,
  users,
  isAdmin,
  user,
  eventIsOpen,
  setEventIsOpen,
}: {
  eventId: string;
  users: OwnUser[];
  isAdmin: boolean;
  user: OwnUser;
  eventIsOpen: boolean;
  setEventIsOpen: any;
}) => {
  const [event, setEvent] = useState<Event>();
  const [participants, setParticpants] = useState<User[]>();
  const [userParticipationsCount, setUserParticipationsCount] =
    useState<number>(0);
  const [userHasJoined, setUserHasJoined] = useState<boolean>();
  const [userParticipationLimitReached, setUserParticipationLimitReached] =
    useState(userParticipationsCount >= user.limit);

  const [eceShow, setEceShow] = useState(false);

  const userLimitReached =
    event &&
    event.users &&
    event.maxParticipants &&
    event.users?.length >= event.maxParticipants;

  useEffect(() => {
    (async () => {
      onSnapshot(doc(db, "events", eventId), (doc) => {
        const a = doc.data() as Event;
        a.id = doc.id;
        setEvent(a);
      });
    })();
    setEceShow(false);
  }, [eventId]);

  useEffect(() => {
    setUserParticipationLimitReached(userParticipationsCount >= user.limit);
  }, [userParticipationsCount, user.limit]);

  useEffect(() => {
    setParticpants(
      event &&
        event.users &&
        users.filter((user) => event.users && event.users.includes(user.uid))
    );
  }, [event, users]);

  useEffect(() => {
    setUserHasJoined(event?.users?.includes(user.uid));
  }, [user.uid, event]);

  useEffect(() => {
    if (event) {
      const getWeek = async () => {
        const getFirstDayOfWeek = (d: Date) => {
          return new Date(
            d.setDate(d.getDate() - d.getDay() + (d.getDay() === 0 ? -6 : 1))
          );
        };
        const getLastDayOfWeek = (d: Date) => {
          return new Date(d.setDate(d.getDate() - d.getDay() + 7));
        };

        const useDate = new Date(event.date.seconds * 1000);

        const q = query(
          collection(db, "events"),
          where("date", ">=", Timestamp.fromDate(getFirstDayOfWeek(useDate))),
          where("date", "<=", Timestamp.fromDate(getLastDayOfWeek(useDate))),
          where("users", "array-contains", user.uid),
          where("countsTowardsLimit", "==", true)
        );

        const querySnapshot = await getDocs(q);
        let a = 0;
        querySnapshot.forEach((doc) => {
          a++;
        });
        setUserParticipationsCount(a);
      };
      getWeek();
    }
  }, [user.uid, event, userParticipationsCount]);

  const join = async () => {
    if (!(userLimitReached && userParticipationLimitReached))
      await updateDoc(doc(db, "events", eventId), {
        users: arrayUnion(user.uid),
      });
  };

  const leave = async () => {
    await updateDoc(doc(db, "events", eventId), {
      users: arrayRemove(user.uid),
    });
  };

  const remove = async () => {
    await deleteDoc(doc(db, "events", eventId)).then(() =>
      setEventIsOpen(false)
    );
  };

  console.log("show event");
  return event ? (
    <Overlay isOpen={eventIsOpen} setIsOpen={setEventIsOpen} hasOffsetButton>
      {!eceShow ? (
        <div className="calendar-event-overlay">
          <div className="ceo-head">
            <div className="ceo-title">{event.title}</div>
            <div className="ceo-time">
              {formattedDateTimeFull(event.start.seconds * 1000)}
            </div>
          </div>
          <div className="ceo-box ceo-participants-wrapper">
            <div className="ceo-box-info">
              <Users />
            </div>
            <div className="ceo-participants">
              {participants && participants.length > 0 ? (
                participants.map((participant, i) => {
                  return (
                    <div
                      key={i}
                      className="inline-flex items-center space-x-2 px-2 mb-2 mr-2 bg-gray-300 rounded"
                      style={{ color: checkContrast(colors.gray[300]) }}
                    >
                      {participant.displayName}
                    </div>
                  );
                })
              ) : (
                <div className="text-center w-full">
                  {event.showMaxUsers &&
                    `${participants?.length || 0} / ${
                      event.maxParticipants && event.maxParticipants > 0
                        ? event.maxParticipants
                        : String.fromCharCode(8734)
                    }`}
                </div>
              )}
            </div>
          </div>
          {event.description && (
            <div className="ceo-box ceo-description-wrapper">
              <div className="ceo-box-info">
                <Info />
              </div>
              <div className="ceo-description">{event.description}</div>
            </div>
          )}

          {userHasJoined ? (
            <Button type="submit" offset onClick={leave}>
              Leave
            </Button>
          ) : (
            <Button
              type="submit"
              onClick={join}
              data-tip={
                userParticipationLimitReached
                  ? "You reached your limit"
                  : userLimitReached
                  ? "This event is full"
                  : ""
              }
              disabled={
                (userLimitReached || userParticipationLimitReached) &&
                event.countsTowardsLimit
              }
              offset
            >{`Join ( ${
              !event.countsTowardsLimit
                ? "free"
                : `${userParticipationsCount} / ${user.limit}`
            })`}</Button>
          )}

          {isAdmin && (
            <div className="event-admin">
              <Button round icon color="alert" onClick={remove}>
                <Trash2 />
              </Button>
              <Button round icon onClick={() => setEceShow(true)}>
                <Edit />
              </Button>
            </div>
          )}
        </div>
      ) : (
        event && (
          <CalendarEventEdit
            type="edit"
            eventID={event.id}
            show={eceShow}
            setShow={setEceShow}
          />
        )
      )}

      <ReactTooltip />
    </Overlay>
  ) : null;
};

export default CalendarEvent;
