import { doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import Header from "./components/Header";
import { db } from "./firebase/firebase";
import NotFound from "./pages/404";
import Admin from "./pages/Admin";
import Events from "./pages/Events";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SettingsPage from "./pages/Settings";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import { Settings } from "./types";

function App() {
  const setVariable = (name: string, value: string) =>
    document.documentElement.style.setProperty(name, value);

  useEffect(() => {
    (async () => {
      onSnapshot(doc(db, "customer", "settings"), (settingsDoc) => {
        const data = settingsDoc.data() as Settings;
        setVariable("--primary", data.colors.primary);
        setVariable("--secondary", data.colors.secondary);
      });
    })();
  });

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/events" element={<Events />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
