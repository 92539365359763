import {
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signOut,
  updateProfile,
  User,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Upload } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Overlay } from "../components/Overlay";
import Button from "../components/ui/Button";
import { db } from "../firebase/firebase";

const Settings = () => {
  const [user, setUser] = useState<User | null>(null);
  const [reauthenticateIsOpen, setReauthenticateIsOpen] = useState(false);
  const [settingsDisplayName, setSettingsDisplayName] = useState<
    User["displayName"] | undefined
  >(user?.displayName);
  const [settingsEmail, setSettingsEmail] = useState<User["email"] | undefined>(
    user?.email
  );
  const [pw, setPw] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [reauthenticateLoading, setReauthenticateLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (u) => setUser(u));
  }, []);

  useEffect(() => {
    setSettingsDisplayName(user?.displayName);
    setSettingsEmail(user?.email);
  }, [user?.displayName, user?.email]);

  const updateUser = async (e: any) => {
    e.preventDefault();

    setUpdateLoading(true);

    if (user) {
      if (user?.displayName !== settingsDisplayName) {
        updateProfile(user, {
          displayName: settingsDisplayName,
        })
          .then(async () => {
            await updateDoc(doc(db, "users", user?.uid), {
              displayName: settingsDisplayName,
            }).then(() => setUpdateLoading(false));
          })
          .catch((error) => {
            console.log(error);
          });
      } else console.log("no name update");

      if (settingsEmail && user.email !== settingsEmail) {
        verifyBeforeUpdateEmail(user, settingsEmail)
          .then(() => {
            signOut(getAuth())
              .then(() => {
                navigate("/sign-in");
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
            setUpdateLoading(false);
            setReauthenticateIsOpen(true);
          });
      } else console.log("no email update");
    }
    setReauthenticateLoading(false);
  };

  const reauthenticate = async (e: any) => {
    e.preventDefault();
    if (user && user.email && pw) {
      const credential = EmailAuthProvider.credential(user?.email, pw);
      await reauthenticateWithCredential(user, credential).then(() =>
        setReauthenticateIsOpen(false)
      );
    }
  };

  const handleSettingsDisplayName = (e: any) => {
    setSettingsDisplayName(e.target.value);
  };
  const handleSettingsEmail = (e: any) => {
    setSettingsEmail(e.target.value);
  };
  const handlePW = (e: any) => {
    setPw(e.target.value);
  };
  return (
    <div className="container">
      {user && settingsDisplayName && settingsEmail && (
        <>
          <h2 className="headline">Settings</h2>
          <form onSubmit={updateUser}>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                value={settingsDisplayName}
                onChange={handleSettingsDisplayName}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                value={settingsEmail}
                onChange={handleSettingsEmail}
              />
            </div>
            <Button
              type="submit"
              offset
              disabled={
                updateLoading ||
                (settingsEmail === user.email &&
                  settingsDisplayName === user.displayName)
              }
            >
              <span>Update</span>
              <Upload />
            </Button>
          </form>
          <Overlay
            isOpen={reauthenticateIsOpen}
            setIsOpen={setReauthenticateIsOpen}
            hasOffsetButton
          >
            <p className="mb-4">
              Your last sign in has been a while. Please reauthenticate.
            </p>
            <form onSubmit={reauthenticate}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={user.email || ""}
                  onChange={handlePW}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input type="password" onChange={handlePW} />
              </div>
              <Button type="submit" offset disabled={reauthenticateLoading}>
                Reauthenticate
              </Button>
            </form>
          </Overlay>
        </>
      )}
    </div>
  );
};

export default Settings;
