import { Switch } from "@headlessui/react";

const CustomSwitch = ({
  toggle,
  onChange: onchange,
}: {
  toggle: boolean;
  onChange: () => void;
}) => (
  <Switch
    checked={toggle}
    onChange={onchange}
    className={`
      transition-colors relative inline-flex h-4 w-7 items-center rounded-full 
      ${toggle ? "bg-primary" : "bg-gray-300"} 
    `}
  >
    <span
      className={`
        inline-block h-[10px] w-[10px] transform rounded-full bg-white transition 
        ${toggle ? "translate-x-[16px]" : "translate-x-[2px]"} 
      `}
    />
  </Switch>
);

export default CustomSwitch;
