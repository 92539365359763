import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Clock,
  PlusCircle,
  Users,
} from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import CalendarEvent from "../components/CalendarEvent";
import CalendarEventEdit from "../components/CalendarEventEdit";
import Loading from "../components/Loading";
import { Overlay } from "../components/Overlay";
import { db } from "../firebase/firebase";
import { checkContrast } from "../shared/colorHelpers";
import {
  compareDates,
  formattedDay,
  formattedTime,
  formattedWeekDay,
  sameDay,
} from "../shared/timeHelper";
import { Event, OwnUser, Settings } from "../types";

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../tailwind.config.js");

const fullConfig = resolveConfig(tailwindConfig);
//const colors = require("tailwindcss/colors");

const Events = () => {
  const [events, setEvents] = useState<Event[]>();
  const [users, setUsers] = useState<OwnUser[]>();
  const [DBuser, setDBuser] = useState<OwnUser>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [settings, setSettings] = useState<Settings>();

  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingDBuser, setLoadingDBuser] = useState(true);
  const [loadingIsAdmin, setLoadingIsAdmin] = useState(true);

  const [aceDate, setAceDate] = useState<Date>();
  const [aceShow, setAceShow] = useState(false);

  const [event, setEvent] = useState<Event>();
  const [eventDate, setEventDate] = useState<Date>();
  const [eventIsOpen, setEventIsOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    onSnapshot(collection(db, `events`), (snapshot) => {
      setEvents(
        snapshot.docs.map((doc) => {
          const a = doc.data();
          a.id = doc.id;
          return a;
        }) as Event[]
      );
      setLoadingEvents(false);
    });
  }, []);

  useEffect(() => {
    onSnapshot(collection(db, `users`), (snapshot) => {
      setUsers(snapshot.docs.map((doc) => doc.data()) as OwnUser[]);
      setLoadingUsers(false);
    });
  }, []);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (u) => {
      if (u) {
        setUser(u);
        setLoadingUser(false);
        if (users) {
          setDBuser(users.find((uu) => uu.uid === u.uid));
          setLoadingDBuser(false);
        }
        setIsAdmin(DBuser?.isAdmin || false);
        setLoadingIsAdmin(false);
      } else navigate("/");
    });
  }, [DBuser?.isAdmin, navigate, users]);

  useEffect(() => {
    (async () => {
      await getDoc(doc(db, "customer", "settings")).then((d) =>
        setSettings(d.data() as Settings)
      );
    })();
  }, []);

  const isLoading =
    loadingEvents &&
    loadingUsers &&
    loadingUser &&
    loadingDBuser &&
    loadingIsAdmin;

  const showAddCalendarEvent = (date: Date) => {
    setAceDate(date);
    setAceShow(true);
  };

  const showEvent = (event: Event, date: Date) => {
    setEvent(event);
    setEventDate(date);
    setEventIsOpen(true);
  };

  return (
    <div className="container">
      {!isLoading ? (
        user && user.emailVerified && DBuser ? (
          events && users && settings ? (
            <>
              <Calendar
                nextLabel={<ChevronRight />}
                prevLabel={<ChevronLeft />}
                next2Label={<ChevronsRight />}
                prev2Label={<ChevronsLeft />}
                formatDay={() => ""}
                tileClassName={({ view, date }) =>
                  view === "month" ? `group` : null
                }
                onClickDay={(value, event) => event.stopPropagation()}
                tileContent={({ date, view }) => {
                  const isToday = events.find((event: Event) =>
                    compareDates(new Date(event.start.seconds * 1000), date)
                  );

                  return (
                    <>
                      {view === "month" && (
                        <div className="w-full py-2 px-1 md:py-1 flex items-center justify-between space-x-4 border-t md:border-t-0 md:border-b border-gray-300">
                          <div className="flex items-center space-x-2">
                            <span
                              className={`w-[3ch] px-1 text-right ${
                                date.getDate() === new Date().getDate()
                                  ? "text-primary font-bold"
                                  : ""
                              }`}
                            >
                              {formattedDay(date)}
                            </span>
                            <span className="md:hidden text-gray-400 text-xs">
                              {formattedWeekDay(date)}
                            </span>
                          </div>
                          <PlusCircle
                            className="group-hover:opacity-100 opacity-100 md:opacity-0 transition-opacity duration-200 ease-in-out w-4 h-4 text-primary"
                            onClick={() => showAddCalendarEvent(date)}
                          />
                        </div>
                      )}
                      {view === "month" && isToday && events.length > 0 && (
                        <>
                          <div className="calendar-events mb-2">
                            {events.map((event, i) => {
                              const newDate = new Date(
                                event.start.seconds * 1000
                              );
                              if (sameDay(newDate, date))
                                return (
                                  <div
                                    key={i}
                                    className="calendar-event"
                                    style={{
                                      background:
                                        event.color ||
                                        fullConfig.theme?.colors?.["primary"],
                                      color: checkContrast(
                                        event.color ||
                                          fullConfig.theme?.colors?.["primary"]
                                      ),
                                    }}
                                    onClick={() => showEvent(event, date)}
                                  >
                                    <div className="ce-title">
                                      {event.title}
                                    </div>
                                    <div className="ce-item ce-time">
                                      <Clock />
                                      <span>
                                        {formattedTime(
                                          event.start.seconds * 1000
                                        )}
                                        {" - "}
                                        {formattedTime(
                                          event.end.seconds * 1000
                                        )}
                                      </span>
                                    </div>
                                    {event.showMaxUsers && (
                                      <div className="ce-item ce-users">
                                        <Users />
                                        <span>
                                          {`${event.users?.length || 0} / ${
                                            event.maxParticipants ||
                                            String.fromCharCode(8734)
                                          }
                                      `}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              else return null;
                            })}
                          </div>
                        </>
                      )}
                    </>
                  );
                }}
              />
              {aceDate && (
                <Overlay
                  isOpen={aceShow}
                  setIsOpen={setAceShow}
                  hasOffsetButton
                >
                  <CalendarEventEdit
                    type="create"
                    date={aceDate}
                    isAdmin={isAdmin}
                    settings={settings}
                    show={aceShow}
                    setShow={setAceShow}
                  />
                </Overlay>
              )}
              {event && eventDate && (
                <CalendarEvent
                  eventId={event.id}
                  users={users}
                  isAdmin={isAdmin}
                  user={DBuser}
                  eventIsOpen={eventIsOpen}
                  setEventIsOpen={setEventIsOpen}
                />
              )}
            </>
          ) : (
            <Loading inContainer noBg />
          )
        ) : (
          <div>
            You have to verify your email first.{" "}
            <Link to="/profile">Go to profile</Link>
          </div>
        )
      ) : (
        <Loading inContainer noBg />
      )}
    </div>
  );
};

export default Events;
