import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Check, Edit, Minus, Plus, Upload, X } from "react-feather";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { OverlayStandalone } from "../components/Overlay";
import Button from "../components/ui/Button";
import CustomSwitch from "../components/ui/Switch";
import { db } from "../firebase/firebase";
import { OwnUser, Settings } from "../types";

const Admin = () => {
  const [users, setUsers] = useState<OwnUser[]>();
  const [DBuser, setDBuser] = useState<OwnUser>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [settings, setSettings] = useState<Settings>();
  const [defaultUserLimit, setDefaultUserLimit] = useState<number>(0);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [doneSettings, setDoneSettings] = useState(false);
  const [colorPrimary, setColorPrimary] = useState<string>();
  const [colorSecondary, setColorSecondary] = useState<string>();

  const updateSettingsDisabled =
    loadingSettings ||
    (settings?.defaultUserLimit === defaultUserLimit &&
      settings?.colors?.primary === colorPrimary &&
      settings?.colors?.secondary === colorSecondary);

  const navigate = useNavigate();

  useEffect(() => {
    onSnapshot(collection(db, `users`), (snapshot) => {
      setUsers(snapshot.docs.map((doc) => doc.data()) as OwnUser[]);
    });
  }, []);

  useEffect(() => {
    users?.sort((a, b) => {
      let fa = a.displayName!.toLowerCase(),
        fb = b.displayName!.toLowerCase();

      if (fa < fb) return -1;
      if (fa > fb) return 1;
      return 0;
    });
  }, [users]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (u) => {
      if (u) {
        if (users) setDBuser(users.find((uu) => uu.uid === u.uid));
        setIsAdmin(DBuser?.isAdmin || false);
      } else navigate("/");
    });
  }, [DBuser?.isAdmin, navigate, users]);

  useEffect(() => {
    (async () => {
      onSnapshot(doc(db, "customer", "settings"), (settingsDoc) => {
        const data = settingsDoc.data() as Settings;
        setSettings(data);
        setDefaultUserLimit(data.defaultUserLimit);
        setColorPrimary(data.colors.primary);
        setColorSecondary(data.colors.secondary);
      });
    })();
  }, [settings?.defaultUserLimit]);

  const updateSettings = async (e: any) => {
    e.preventDefault();
    setLoadingSettings(true);
    setDoneSettings(false);

    await updateDoc(doc(db, "customer", "settings"), {
      defaultUserLimit: defaultUserLimit,
      colors: {
        primary: colorPrimary,
        secondary: colorSecondary,
      },
    }).then(() => {
      setLoadingSettings(false);
      setDoneSettings(true);
    });
  };

  const handleDefaultUserLimit = (e: any) => {
    prepareSetDefaultUserLimit(e.target.value);
  };
  const handleColorPrimary = (e: any) => {
    setColorPrimary(e.target.value);
  };
  const handleColorSecondary = (e: any) => {
    setColorSecondary(e.target.value);
  };

  const prepareSetDefaultUserLimit = (limit: number) => {
    if (limit >= 0) setDefaultUserLimit(limit);
    else setDefaultUserLimit(0);
  };

  return (
    <>
      <div className="container">
        {settings ? (
          <form className="admin-settings" onSubmit={updateSettings}>
            <div className="settings-item">
              <div className="settings-item-title">Default User Limit</div>
              <div className="settings-item-content settings-item-content-default-user-limit">
                <div className="flex items-center space-x-2">
                  <div
                    className="sidul-down"
                    onClick={() =>
                      prepareSetDefaultUserLimit(Number(defaultUserLimit!) - 1)
                    }
                  >
                    <Minus />
                  </div>
                  <input
                    type="number"
                    className="sidul-value input-number-like-textfield"
                    value={defaultUserLimit}
                    onChange={handleDefaultUserLimit}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <div
                    className="sidul-up"
                    onClick={() =>
                      prepareSetDefaultUserLimit(Number(defaultUserLimit!) + 1)
                    }
                  >
                    <Plus />
                  </div>
                </div>
              </div>
            </div>

            <div className="settings-item">
              <div className="settings-item-title">Primary Color</div>
              <div className="settings-item-content">
                <input
                  type="color"
                  value={colorPrimary}
                  onChange={handleColorPrimary}
                />
                <input
                  type="text"
                  value={colorPrimary}
                  onChange={handleColorPrimary}
                />
              </div>
            </div>

            <div className="settings-item">
              <div className="settings-item-title">Secondary Color</div>
              <div className="settings-item-content">
                <input
                  type="color"
                  value={colorSecondary}
                  onChange={handleColorSecondary}
                />
                <input
                  type="text"
                  value={colorSecondary}
                  onChange={handleColorSecondary}
                />
              </div>
            </div>

            <Button type="submit" offset disabled={updateSettingsDisabled}>
              <span>Update</span>
              {!loadingSettings && !doneSettings && <Upload />}
              {loadingSettings && <Loading inline noFloat />}
              {doneSettings && <Check />}
            </Button>
          </form>
        ) : (
          <Loading inContainer noBg />
        )}
      </div>

      <div className="container">
        {isAdmin ? (
          <div className="overflow-auto w-full">
            <table className="admin-users">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Limit</th>
                  <th>Mail</th>
                  <th>Verified</th>
                  <th>Admin</th>
                  <th>uid</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, i) => {
                  const copy = async (e: any) => {
                    await navigator.clipboard.writeText(e.target.value);
                    console.log("copied");
                  };

                  const toggleAdmin = async (user: OwnUser) => {
                    await updateDoc(doc(db, "users", user.uid), {
                      isAdmin: !user.isAdmin,
                    });
                  };

                  const updateLimit = async (e: any) => {
                    await updateDoc(doc(db, "users", user.uid), {
                      limit: e.target.value > 7 ? 7 : e.target.value,
                    });
                  };

                  return (
                    <tr key={i} className="au-item au-name">
                      <td className="font-bold">{user.displayName}</td>
                      <td>
                        <input
                          type="number"
                          value={Number(user.limit)}
                          onChange={updateLimit}
                          className="input-invisible"
                          max={7}
                          min={0}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input-invisible truncate cursor-pointer"
                          onClick={copy}
                          value={user.email || ""}
                          readOnly
                        />
                      </td>
                      <td className="text-center">
                        {user.emailVerified ? <Check /> : <X />}
                      </td>
                      <td className="text-center">
                        <CustomSwitch
                          toggle={user.isAdmin}
                          onChange={() => toggleAdmin(user)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input-invisible truncate cursor-pointer"
                          onClick={copy}
                          value={user.uid}
                          readOnly
                        />
                      </td>
                      {false && (
                        <td>
                          <OverlayStandalone trigger={<Edit />}>
                            <div className="user-edit">{user.displayName}</div>
                          </OverlayStandalone>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <Loading inContainer noBg />
        )}
      </div>
    </>
  );
};

export default Admin;
