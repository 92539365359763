import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);

export const checkContrast = (bgColor: string, reverse?: boolean) => {
  let bgValue;
  // check if hex color. else its a var()
  if (bgColor.includes("#")) {
    bgValue = bgColor;
  } else {
    // get styles from body > get value of the variable in bgColor after removing the containing var()
    bgValue = getComputedStyle(document.documentElement).getPropertyValue(
      bgColor.trim().replace("var(", "").replace(")", "")
    );
  }

  const color = bgValue.charAt(0) === "#" ? bgValue.substring(1, 7) : bgValue;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });

  const light =
    typeof fullConfig.theme?.colors === "object"
      ? (fullConfig.theme.colors?.["textColorLight"] as string)
      : "#ffffff";

  const dark =
    typeof fullConfig.theme?.colors === "object"
      ? (fullConfig.theme.colors?.["textColor"] as string)
      : "#000000";

  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  if (reverse) return L < 0.179 ? dark : light;
  else return L > 0.179 ? dark : light;
};
