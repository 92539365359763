import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [user, setUser] = useState<User | null>(null);

  const auth = getAuth();
  onAuthStateChanged(auth, (u) => {
    if (u) setUser(u);
  });
  return (
    <div className="container">
      {user ? (
        <>
          <h1>
            Welcome <span className="text-primary">{user.displayName}</span>
          </h1>
          <div className="home">
            <Link to="/events" className="home-tile">
              <div className="home-tile-title">Events</div>
              <div className="home-tile-stats">
                <div className="home-tile-stat">This week: 4</div>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <>
          <h1>Welcome stranger</h1>
        </>
      )}
    </div>
  );
};

export default Home;
