import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  User,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";

const Profile = () => {
  const [user, setUser] = useState<User | null>(null);
  const [verificationClicked, setVerificationClicked] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (u) => {
      if (u) {
        setUser(u);
      }
    });
  });

  const sendVerificationMail = () => {
    if (user) {
      sendEmailVerification(user);
      setVerificationClicked(true);
    }
  };

  return (
    <div className="container">
      {user ? (
        <>
          <div className="profile">
            <h1>{user.displayName}'s Profile</h1>
            <div className="flex items-center space-x-2 text-sm">
              <span className="text-gray-500">
                {user.email} ({user.emailVerified ? "verified" : "not verified"}
                )
              </span>
              {!user.emailVerified && !verificationClicked && (
                <span
                  className="text-primary cursor-pointer"
                  onClick={sendVerificationMail}
                >
                  Send verification mail
                </span>
              )}
            </div>
            <Link to="/settings">Settings</Link>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Profile;
